<div *ngIf="isMobile && hamburgerClicked" class="overlay"></div>
<div *ngIf="showBanner" class="banner">
    <h1>Viewing as {{userData.FirstName}} {{userData.LastName}} - {{userRights}}, {{selectedClientName}}</h1>
</div>
<app-header [(showBanner)]="showBanner" [(selectedClientName)]="selectedClientName"
    [isMobile]="isMobile" (onHamburgerClick)="openSideNavMenu($event)" [desktop]="desktop">
</app-header>
<div *ngIf="isMobile && hamburgerClicked" #mobileSidenav id="mobileSidenav" [@slideInOut] class="mobile-sidenav">
    <mat-nav-list>
        <button class="close" mat-button (click)="closeSideNavMenu()">X</button>
        <div class="icon-container" [ngClass]="{'selected': selectedPage == 'pinsheet'}">
            <a [routerLink]="['/pinsheet']" (click)="closeSideNavMenu();setSelectedPage('pinsheet')" routerLinkActive="active">
                <img class="icon" src="../../../../assets/images/Sidenav/pinsheet.svg" alt="pinsheet"/>
                <span class="icon-name">Pinsheet</span>
            </a>
        </div>
        <div [hidden]="user" class="icon-container" [ngClass]="{'selected': selectedPage == 'manage'}">
            <a [routerLink]="['/manage']" (click)="closeSideNavMenu();setSelectedPage('manage')" routerLinkActive="active">
                <img class="icon" src="../../../../assets/images/Sidenav/manageGreen.svg" alt="manage"/>
                <span class="icon-name">Manage Greens</span>
            </a>
        </div>
        <div class="icon-container" [ngClass]="{'selected': selectedPage == 'events'}">
            <a [routerLink]="['/events']" (click)="closeSideNavMenu();setSelectedPage('events')" routerLinkActive="active">
                <img class="icon" src="../../../../assets/images/Sidenav/event.svg" alt="events"/>
                <span class="icon-name">Events</span>
            </a>
        </div>
        <div class="icon-container" [ngClass]="{'selected': selectedPage == 'help'}">
            <a [routerLink]="['/help']" (click)="closeSideNavMenu();setSelectedPage('help')" routerLinkActive="active">
                <img class="icon " src="../../../../assets/images/Sidenav/help.svg" alt="help"/>
                <span class="icon-name">Help</span>
            </a>
        </div>
    </mat-nav-list>
</div>
<mat-sidenav-container class="side-navigations" [ngClass]="{'imp-side-navigations': showBanner}">
    <mat-sidenav *ngIf="!isMobile" opened class="sidenav-list-container" mode="side">
        <mat-nav-list>
            <div matTooltip="Pinsheet">
                <a [routerLink]="['/pinsheet']" routerLinkActive="active"><img class="pinsheet"
                        src="../../../../assets/images/Sidenav/pinsheet.svg" alt="pinsheet " /></a>
            </div>
            <div [hidden]="user || member" matTooltip="Manage Greens" matTooltipClass="side-menu">
                <a [routerLink]="['/manage']" routerLinkActive="active"><img class="manage"
                        src="../../../../assets/images/Sidenav/manageGreen.svg" alt="manage " /></a>
            </div>
            <div matTooltip="Events" matTooltipClass="side-menu">
                <a [routerLink]="['/events']" routerLinkActive="active"><img class="events"
                        src="../../../../assets/images/Sidenav/event.svg" alt="events " /></a>
            </div>
            <div matTooltip="Help" matTooltipClass="side-menu">
                <a [routerLink]="['/help']" routerLinkActive="active"><img class="help "
                        src="../../../../assets/images/Sidenav/help.svg" alt="help " /></a>
            </div>
            <div [hidden]="true">
                <a [routerLink]="['/client-info']" routerLinkActive="active"><img class="client_info "
                        src="../../../../assets/images/Sidenav/help.svg" alt="client-info " /></a>
            </div>
            <div [hidden]="true">
                <a [routerLink]="['/user-info']" routerLinkActive="active"><img class="user_info "
                        src="../../../../assets/images/Sidenav/help.svg" alt="user-info " /></a>
            </div>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content id="main-content">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
<!--<app-footer></app-footer>-->