import { Component, OnInit, Input, Output, TemplateRef, EventEmitter, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/core/services/http/http.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { selectClientsProfileCollection } from 'src/app/store/selectors/clients.selectors';
import { clientsProfile } from 'src/app/store/actions/clients.actions';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {
  @ViewChild("selectPlaybookRef") selectPlaybookRef: TemplateRef<any>;
  playbookToken: string
  selectedClientId: any
  playbookNames: any
  selectedPlaybook: string = "Select playbook to download"
  playbookNameArray: any
  clientsProfile$ = this.store.select(selectClientsProfileCollection)
  clientProfileData: any
  enablePrint: boolean = false;
  cdn_url: string = environment.CDN_URL;
  @Input() isSuperAdmin: boolean = false;
  @Output() closeNotification = new EventEmitter<boolean>();
  @Output() printMemberAccessFlyer = new EventEmitter<any>();
  @Output() openMemberPinsheetPage = new EventEmitter<any>();

  constructor(
    private router:Router, 
    private http: HttpService,
    private cookie: CookieService, 
    public dialog: MatDialog,
    private store: Store
  ) { 
    this.clientsProfile$.subscribe((data) => {
      this.clientProfileData = data
    })
  }
  closeModal(value:boolean) {
    this.closeNotification.emit(value)
  }
  downloadMyFile(){
    window.open('../../../assets/images//Adminguide.pdf','_blank')
  }
  viewPlaybook() {
    if(this.playbookNames.length > 1) {
      this.dialog.open(this.selectPlaybookRef, { disableClose: true, panelClass: 'playbook-modal' })
    }
    else {
      window.open(`${environment.BASE_URL}/api/clients/playbook/view?fileName=${this.selectedPlaybook}&playbookToken=${this.playbookToken}`, '_blank');
    }
  }
  setSelectedPlaybook(value: any) {
    this.selectedPlaybook = value
    window.open(`${environment.BASE_URL}/api/clients/playbook/view?fileName=${this.selectedPlaybook}&playbookToken=${this.playbookToken}`, '_blank');
  }
  onPlaybookSelection(value: any) {
    
  }

  closePlaybookDialog(){
    this.selectedPlaybook = "Select playbook to download"
  }
 
  ngOnInit(): void {
    this.selectedClientId = this.cookie.get("clientId")
    this.http.get(`/api/clients/playbook/names?clientId=${this.selectedClientId}`)
      .subscribe((data:any) =>
      {
        if(data.Status == 'Success') {
          this.playbookToken = data.Data.PlaybookToken
          this.playbookNames = data.Data.PlaybookNames
          if(this.playbookNames.length == 1)
            this.selectedPlaybook = this.playbookNames[0]
          this.playbookNameArray = this.playbookNames.map((value: any, index: any) => {
            return {
              id: index + 1,
              name: value
            };
          })
        }
      }
    )

    if(this.selectedClientId)
      this.getClientsProfile(`/api/clients/profile?clientId=${this.selectedClientId}`)

    console.log(this.playbookNameArray)
  }

  getClientsProfile(path: string) {
    this.http.get(path)
      .subscribe((data) => {
        if (data.Status == 'Success') {
          const clientsProfileData = data.Data
          this.clientProfileData = clientsProfileData
          this.store.dispatch(clientsProfile({ clientsProfileData }))
        }
      })
  }

  downloadMemberAccessFlyer(event: MouseEvent) {
    this.printMemberAccessFlyer.emit(event);
  }

  openMemberPinsheet(event: MouseEvent) {
    this.openMemberPinsheetPage.emit(event)
  }

  navigateToTrainingLibrary() {
    this.closeModal(false)
    this.router.navigate(['training-library'])
  }

  openMemberAccessPdf() {
    window.open('../../../assets/images/MEMBER ACCESS.pdf','_blank')
  }

}
