<mat-grid-list class="header-grid" cols="1">
    <div class="mobile-header-container" *ngIf="isMobile">
        <div class="header-menu-logo">
            <div class="hamburger-menu" #hamburgerMenu (click)="openMenu()">
                <div class="menu-icon"></div>
                <div class="menu-icon"></div>
                <div class="menu-icon"></div>
            </div>
            <div class="logo-container">
                <div class="logo-frame" [ngClass]="{'imp-logo-frame': showBanner}">
                    <img src="../../../assets/images/header-logo.svg">
                </div>
                <div class="logo-frame-2" [ngClass]="{'imp-logo-frame-2': showBanner}">
                    <img src="../../../assets/images/header-logo.svg">
                </div>
            </div>
        </div>
        <div (click)="toggleProfileMenu()" #porfileIcon>
            <mat-icon class="profile-icon">account_circle</mat-icon>
        </div>
    </div>
    <div *ngIf="isMobile && profileMenuOpen" #profileMenu class="profile-menu">
        <ul>
            <li *ngIf="superAdmin && !impersonate" (click)="openImpersonateDialog(); toggleProfileMenu()">IMPERSONATE</li>
            <li *ngIf="superAdmin && impersonate" (click)="onImpersonateExit(); toggleProfileMenu()">EXIT IMPERSONATE</li>
            <hr *ngIf="superAdmin"/>
            <li class="show-alert" [routerLink]="['/alerts']" (click)="toggleProfileMenu()">
                <div id="alert-models"></div>
                <span>ALERTS</span>
                <img width="17px" height="18px" src="../../../../assets/images/notification.svg">
            </li>
            <hr/>
            <li (click)="showResource(); toggleProfileMenu()" id='resourceButtonMobile'>RESOURCES</li>
            <hr/>
            <li [routerLink]="['/myaccount']" (click)="toggleProfileMenu()">MY ACCOUNT</li>
            <hr/>
            <li (click)="logOut(); toggleProfileMenu()">LOG OUT</li>
        </ul>
    </div>
    <div class="header-container" *ngIf="!isMobile">
        <div class="logo-frame" [ngClass]="{'imp-logo-frame': showBanner}">
            <img src="../../../assets/images/header-logo.svg">
        </div>
        <div class="logo-frame-2" [ngClass]="{'imp-logo-frame-2': showBanner}">
            <img src="../../../assets/images/header-logo.svg">
        </div>
        <div class="header-nav" [ngClass]="{'imp-header-nav': showBanner}">
            <ul>
                <li *ngIf="superAdmin && !impersonate" (click)="openImpersonateDialog()">IMPERSONATE</li>
                <li *ngIf="superAdmin && impersonate" (click)="onImpersonateExit()">EXIT IMPERSONATE</li>
                <li class="show-alert">
                    <div id="alert-models"></div>
                    <img [routerLink]="['/alerts']" width="17px" height="18px" src="../../../../assets/images/notification.svg">
                </li>
                <li (click)="showResource()" id='resourceButton'>RESOURCES</li>
                <li [routerLink]="['/myaccount']">MY ACCOUNT</li>
                <li (click)="logOut()">LOG OUT</li>
            </ul>
        </div>
    </div>
    <app-resources 
        id='resource' *ngIf="showNotification" (printMemberAccessFlyer)="enableMemberAccessFlyer($event)" 
        (closeNotification)="hideNotification()" [isSuperAdmin]="superAdminView" (openMemberPinsheetPage)="openMemberPinsheetPage($event)">
    </app-resources>
</mat-grid-list>

<ng-template #impersonateDialogRef class="custom-modal">
    <button class="close" mat-button (click)="onImpersonateDialogClose()">X</button>
    <div class="client-dropdown">
        <p>CLIENT</p>
        <app-impersonate-dropdown class="dropdown_1"
            [label]="clientLabel" (selectedId)="onSelectClientId($event)"
            (setSelected)="setSelectedClientName($event)"  [page]="'impersonateDialog'"
            [dropdownList]="clientsData" [isMobile]="isMobile">
        </app-impersonate-dropdown>
    </div>
    <div class="user-dropdown">
        <p>USER</p>
        <app-dropdown [label]="userLabel || 'SELECT USER'"
            [Isshow]="false" (setSelected)="setUserSelectedValue($event)"
            (selectedId)="onUserSelection($event)" [dropdownArr]="userList"
            [page]="'impersonateDialog'" [userRight]="userRight" [isFont]="isMobile || !desktop">
        </app-dropdown>
    </div>
    <div class="impersonate-button">
        <button class="theme-button" (click)="onImpersonate()" [disabled]="userLabel=='SELECT USER'" 
            [ngClass]="{'disabled': userLabel=='SELECT USER'}">SUBMIT
        </button>
    </div>
</ng-template>


<div *ngIf="enablePrint" class="print-container print-area">
    <img src="../../../../assets/images/Member Pinsheet Flyer.png" id="instructions-bg-image" (load) ="onLoadFlyer()" (error)="onFlyerLoadingError($event)" />
    <qrcode id="member-url-qr-code" [qrdata]="memberFlyerQRCodeURL" [width]="130" [margin]="2"></qrcode>
    <!-- <a id="member-page-url" href="{{memberFlyerQRCodeURL}}">{{memberFlyerQRCodeURL}}</a> -->
</div>